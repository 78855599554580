<template>
<div class="subscription-container" :style="{height: innerHeight + 'px'}">
    <div class="top">

        <!-- do you have a promo code? -->
        <div class="header">
            <h2 style="margin: 0;">{{ $t('profile.subscription.title') }}</h2>
            
        </div>
        <div class="plan">
            <div class="details">
                <img src="../../assets/icons/subscription.png" />
                <div class="info">
                    <p>{{ $t('profile.subscription.plan.title') }}</p>
                    <p>{{ $t('profile.subscription.plan.caption') }}</p>
                </div>
            </div>
            <div class="price">
                <p :style="{'text-decoration': (validatePromoCodeResult == 'success' || (lastTransaction != null && lastTransaction.coupon != null && lastTransaction.status == 'PENDING')) ? 'line-through' : 'none'}">4.99€</p>
                <p v-if="(validatePromoCodeResult == 'success'  || (lastTransaction != null && lastTransaction.coupon != null && lastTransaction.status == 'PENDING'))" style="color: rgba(var(--vs-primary)); font-weight: bold; font-size: 1.5rem;"> {{ (4.99 * (1 - ((lastTransaction != null && lastTransaction.coupon != null && lastTransaction.status == 'PENDING') ? lastTransaction.coupon.discount_percentage : validatePromoCodePercent) / 100)).toFixed(2) }}€</p>
                <p>/{{ $t('common.year') }}</p>
            </div>
        </div>

        <div class="header" v-if="lastTransaction == null || lastTransaction.status != 'ACTIVE'">
            <vs-input v-model="promoCode" :placeholder="$t('profile.subscription.promo.placeholder')" block border :loading="validatePromoCodeLoading" :state="validatePromoCodeResult == null ? undefined : (validatePromoCodeResult == 'error' ? 'danger' : 'success')">
                <template #icon>
                    <img class="inactive" style="width: 32px;" src="../../assets/icons/menu/admin_coupons.png" v-show="validatePromoCodeResult != 'success'"/>
                    <img class="active" style="width: 32px;" src="../../assets/icons/menu/coupons_active.png" v-show="validatePromoCodeResult == 'success'"/>
                    
                </template>
                <template #message-danger v-if="validatePromoCodeResult == 'error'">
                    <p>{{ $t('profile.subscription.promo.error') }}</p>
                </template>
            </vs-input>
        </div>
    </div>

    



    <div class="bottom">
        <div style="display: flex; justify-content: center;">
            <img src="../../assets/images/subscription_choose.png" style="width: 50%;" />
        </div>
        <vs-button v-if="lastTransaction != null && lastTransaction.status == 'ACTIVE'" size="large" border @click="cancelSubscription">{{ $t('profile.subscription.cancellation.button').toUpperCase() }}</vs-button>
        <vs-button v-if="(lastTransaction == null || (lastTransaction != null && lastTransaction.status != 'ACTIVE'))" size="large" @click="goToInvoicing">{{ $t('common.continue').toUpperCase() }}</vs-button>
    </div>

</div>
</template>

<script>
import cancelSubscriptionDialog from '../dialogs/CancelSubscriptionDialog.vue';
import {
    GlobalEventEmitter
} from '@/utils/GlobalEventEmitter'
import { apiCall } from '../../utils/ApiMiddleware';
export default {
    name: "subscription",
    props: {
        innerHeight: {
            type: Number,
            default: 100,
            required: false
        }
    },
    components: {},
    data() {
        return {
            lastTransaction: null,

            validatePromoCodeLoading: false,
            validatePromoCodeTimeout: null,
            validatePromoCodeResult: null,
            validatePromoCodePercent: 0,
            promoCode: '',

        }
    },

    watch: {
        promoCode(n){
            if(this.validatePromoCodeTimeout != null){
                clearTimeout(this.validatePromoCodeTimeout)
            }

            if(n.length > 0){
                this.validatePromoCodeLoading = true;
                this.validatePromoCodeTimeout = setTimeout(() => {
                    this.validatePromoCode()
                }, 1000)
            } else {
                this.validatePromoCodeLoading = false
                this.validatePromoCodeResult = null
            }
        }

    },

    methods: {

        goToInvoicing(){
            // if current transaction is ACTIVE or DEACTIVATED it means that the user has already a subscription, give an error
            if(this.lastTransaction != null && ['ACTIVE', 'DEACTIVATED'].includes(this.lastTransaction.status)){
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('profile.subscription.alreadyActive'),
                    color: 'primary',
                    position: 'top-right'
                })
                return;
            }


            this.$router.push({
                name: 'Fatturazione',
                params: {
                    coupon: this.validatePromoCodeResult == 'success' ? this.promoCode : '',
                }
            })
        },

        async validatePromoCode(){
            // payload field: name, endpoint: /backoffice/coupons/check
            const response = await apiCall('POST', '/coupons/check', {
                name: this.promoCode
            }, true);
            this.validatePromoCodeLoading = false;

            if(response.status == 200 && response.data.can_be_used){
                this.validatePromoCodeResult = 'success'
                this.validatePromoCodePercent = response.data.discount_percentage
            } else {
                this.validatePromoCodeResult = 'error'
            }
        },

        

        async getLastTransaction() {
            const response = await apiCall('GET', '/subscriptions', {
                limit: 1,
                order: 'desc(end_time)',
                only_active: false
            }, true);
            if (response.status == 200) {
                if (response.data.data != null && response.data.data.length > 0) {
                    return response.data.data[0];
                } else {
                    return null;
                }
            }
            return false;
        },

        async deleteSubscription() {
            const loading = this.$vs.loading();
            const response = await apiCall('DELETE', '/subscriptions');
            loading.close();
            if (response.status == 200) {
               this.$router.push({name: 'Cancellazione'})
            } else {
                this.$vs.notify({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('common.messages.somethingWentWrong'),
                    color: 'danger',
                    position: 'top-right'
                })
            }
            return false;
        },

        cancelSubscription() {
            GlobalEventEmitter.$emit('showDialog', cancelSubscriptionDialog, this.cancelSubscriptionCallback) // chiedo conferma per la cancellazione

        },

        cancelSubscriptionCallback(e){
            if(e){
                this.deleteSubscription();
            }
        }
    },

    async mounted(){
        this.lastTransaction = await this.getLastTransaction()
    }
}
</script>

<style scoped>
.header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

}

.top {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.bottom {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.subscription-container {

    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    justify-content: space-between;
}

.plan {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    gap: 10px;
    border: 1px solid var(--primary);
    border-radius: 10px;
}

.plan .details {
    display: flex;
    align-items: center;
    gap: 10px;
}

.plan .details .info {
    display: flex;
    flex-direction: column;
}

.plan .details .info p:nth-child(1) {
    color: var(--primary) !important;
    font-size: 1.1rem;
}

.plan .price {
    display: flex;
    flex-direction: column;
}

.plan .price p:nth-child(2) {
    font-size: 0.8rem;
    font-weight: 400;
    color: var(--grey-75);
}

p {
    margin: 0;
}

/* MOBILE */
@media (max-width: 600px) {
    .subscription-container {
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        width: 100%!important;
        padding-top: 20px;
        padding-bottom: 110px;
        justify-content: flex-start;
        gap: 20px;
    }

}

</style>

<style>
.subscription-container .vs-input:focus ~ .vs-input__icon {
    transform: scale(1.1)!important;
}

.subscription-container .vs-input-parent--shadow .vs-input-content .vs-input:focus ~ .vs-input__icon {
    background: unset!important;
}

.subscription-container .vs-input:focus~.vs-input__label:not(.vs-input__label--placeholder):not(.vs-input__label--label){
    opacity: 0!important;
}

</style>
